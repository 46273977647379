.page-item {
    margin: 0 4px;
}

.page-link {
    padding: 0;
    width: 40px;
    height: 40px;
    border: 0;
    line-height: 40px;
    text-align: center;
    color: $mouse-gray-lighter;
    background-color: transparent;
    border-radius: 2px;

    &:hover {
        background-color: $white;
        color: $blue-dark;
    }
}

.page-item.active .page-link {
    background-color: $white;
    color: $blue-dark;
    font-weight: $bold;
}
