a.link {
    &--no-decor {
        color: inherit;
        text-decoration: none;
    }

    &--teal {
        color: $teal-much-darker;
    }
}
