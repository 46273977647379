$menu: #2BB7C5;
$menu-link: #FFFFFF;
$blue: #0982B0;
//$blue-dark: #134068;
$blue-new-dark: #0f4069;
$filter-field-border: #c9d4e4;
$very-dark-blue: #212529;
$vivid-red: #EB2138;

$gray: #808080;
$grey-silver: #7b889b;
$gray-light: #b2b2b2;
$gray-dark: #9d9c9c;
$gray-lighter: #e9ecef;
$gray-light-border: #DEE2E6;
$gray-very-dark: #495057;
$cyan-dark: #1C86A0;

///

$blue-dark: #0F3E67;
$blue-darker: #0C3254;
$teal-lighter: #EAF8F9;
$teal-lighter-2: #CEECF3;
$teal: #2BB7C5;
$teal-darker: #26A4B1;
$teal-much-darker: #24869F;
$spike-red-lighter: #FDE7E9;
$spike-red: #ED4558;
$spike-red-darker: #D53E4F;
$apple-green: #B2D335;

$mouse-gray: #425260;
$mouse-gray-lighter: #7E90A1;
$gray-light-bis: #EBEFF3;
$line-gray: #D3D3D3;
$border-gray-transparent: rgba(0, 0, 0, .2);

$white: #FFF;
$black: #000;
