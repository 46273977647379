.form {
    &__checkbox {
        display: flex;
        align-items: center;
        position: relative;

        input[type='checkbox'] {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked + label:before {
                border-color: $blue-dark;
                background: $blue-dark;
                content: $checkbox__mark;
            }
        }

        label {
            display: flex;
            align-items: center;
            margin: 7px 0;
            text-align: left;
            font-size: 13px;

            &:before {
                content: ' ';
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
                margin-right: 10px;
                transition: all 0.2s;
                background: #FFFFFF;
                border: 1px solid #c9d4e4;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                cursor: pointer;
            }

            span {
                cursor: pointer;
            }
        }
    }

    &-check {
        &-label {
            margin-left: 1rem;
        }

        &-inline {
            .form-check-input {
                margin-right: 6px;
            }
        }
    }
}
