#view-project-root.public-project-view {
    .map-container {
        position: relative;
        height: 650px;

        .map-control--container {
            position: absolute;
            z-index: 1;
            top: 5px;
            left: 5px;
            padding: 10px;

            .map-control--inner {
                display: none;
            }

            &:hover {
                background: white;
                border-radius: 4px;

                & > label {
                    display: none;
                }

                .map-control--inner {
                    display: block;
                }
            }
        }

        .color-scale-legend-wrapper {
            top: auto;
            bottom: 180px;
        }
    }

    .stats-box-container {
        & > div {
            text-transform: uppercase;
            padding: 5px 7px;
            color: #fff;
            border-radius: 4px;
            background: #85d9e3;

            &:first-child {
                background: #2ab7c3;
            }

            &:last-child {
                background: #123f66;
            }
        }

        .stats-value {
            font-size: 27px;
        }

        .stats-label {
            line-height: 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
