footer.sw-footer {
    padding: 70px 0 30px 0;
    background: url('/images/background-theme-partial-02.jpg'), $blue-dark;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 80% auto;

    @media (min-width: $mobile) {
        background-size: 50% auto;
    }

    a {
        color: $white;
        opacity: 1;
        transition: opacity 0.125s;
        -webkit-transition: opacity 0.125s;
        text-decoration: none;

        &:hover {
            opacity: 0.75;
        }
    }
}
