.study-case {
    position: relative;

    .study-case-bg {
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .study-case-details {
        position: absolute;
        bottom: 0;
        background-color: rgba(255, 255, 255, .8);
    }

    &--1 {
        & .study-case-bg {
            &:first-child {
                background-image: url('/images/sante1-2.jpg');
            }

            &:nth-child(2) {
                background-image: url('/images/sante2.jpg');
            }
        }
    }

    &--2 {
        & .study-case-bg {
            &:first-child {
                background-image: url('/images/banque1.jpg');
            }

            &:nth-child(2) {
                background-image: url('/images/banque2.jpg');
            }
        }
    }

    &--3 {
        & .study-case-bg {
            &:first-child {
                background-image: url('/images/gr-dist1.jpg');
            }

            &:nth-child(2) {
                background-image: url('/images/gr-dist2.jpg');
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .study-case {
        &--1,
        &--2 {
            padding-top: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .study-case {
        &--1,
        &--2 {
            background-size: cover;
        }
    }
}

.sct-acquisition {
    &__brochure-container {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;

        @media (min-width: $tablet) {
            margin-right: 0;
            margin-top: 0;
        }
    }

    &__brochure {
        box-shadow: 0 21px 21px 0 rgba(103, 134, 123, 0.2);
        background-color: $white;
        padding: 40px;
        border-radius: 4px;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: -30px;
            left: -30px;
            background-color: $teal;
            z-index: -1;
            border-radius: 4px;
        }

        p {
            font-size: 20px;
            line-height: 32px;
        }

        .sw-logo {
            width: 100px;
        }
    }
}

.sct-medias {
    &__logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 16px;
        width: 33.33%;
        max-width: 200px;

        @media (min-width: $tablet) {
            width: 50%;
        }
    }
}
