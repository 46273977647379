.customer-area--dashboard {
    .sw-table {
        &-col {
            padding: 5px 16px;
            min-height: auto;

            &:first-child {
                flex: 7;
            }

            &:nth-child(3) {
                max-width: 160px;
            }

            &:nth-child(4) {
                width: 100px;
                padding: 5px 4px;
            }
        }

        &__head {
            .sw-table-col:last-child {
                flex: inherit;
                width: 78px;
            }
        }

        &__row {
            .sw-table-col:last-child {
                flex: inherit;
            }
        }
    }

    ol {
        li {
            margin-top: 15px;
        }
    }
}
