.search-city {
    position: relative;

    &-inner {
        position: absolute;
        width: 300px;
        right: 0;
        top: 0;
        background: white;
        border-radius: 4px;
        border: 2px solid $border-gray-transparent;
        z-index: 100;
        padding: 1rem 1rem 0;
    }

    &-results {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 5px 5px;
                border-bottom: 1px solid $blue-new-dark;

                &.pointer:hover {
                    background-color: #ececec;
                }
            }
        }

        p {
            padding-top: 1rem;
            text-align: center;
            font-size: 1.5rem;
        }
    }
}
