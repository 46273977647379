.screenshot-maker--container {
    background-color: rgba(255, 255, 255, .75);
    border-radius: 3px;
    padding: .5rem;
}

.screenshot-maker {
    a {
        display: flex;
        color: $gray-very-dark;

        &:hover {
            color: $vivid-red;
            text-decoration: none;
        }

        i {
            width: 26px;
            text-align: center;
            align-self: center;
            padding: 0;
        }

        span {
            flex-grow: 2;
            text-align: center;
        }
    }
}
