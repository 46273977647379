.sct-homepage-hero {
    overflow: hidden;

    &__partners-marquee {
        display:flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        min-width: 100%;
        opacity: 50%;
        padding-top: 50px;

        @media (min-width: $tablet) {
            padding-top: 70px;
        }
    }

    &__partners-logos {
        display : flex;
        flex-shrink: 0;
        align-items: center;
        animation: marquee 30s linear 3s infinite;
        -webkit-animation: marquee 30s 3s linear infinite;

        @keyframes marquee {
            from {
                transform: translateX(0);
            }
            to {
                transform: translateX(-100%);
            }
        }
    }

    &__partners-logo {
        margin: 0 24px;
    }

    .trophee-container {
        position: relative;

        .img-trophee {
            position: absolute;
            width: 250px;
            left: 0;
            bottom: 0;
        }
    }
}

.sct-data-platform {
    padding-top: 0;

    &__title {
        padding-bottom: 160px;
    }

    &__content {
        margin-top: -140px;
    }

    &__container {
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 21px 21px 0 rgba(103, 134, 123, 0.2);
        margin-bottom: 24px;
    }
}

.sct-concurency {
    padding-top: 0;
}
