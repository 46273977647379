.redzone {
    &_app {
        height: 100vh;
        overflow: hidden;
    }

    &-container {
        height: 100%;
        width: 100%;
        display: flex;

        .map-container {
            height: 100%;

            .map-wrapper {
                height: 100%;

                &-inner {
                    height: 100%;
                }
            }
        }
    }
}
