.sct-how-it-works {

    &__steps {
        padding-left: 30px;
        border-left: 2px solid $line-gray;
        margin-left: 20px;

        @media (min-width: $desktop) {
            padding-left: 90px;
        }
    }

    &__step {
        margin-bottom: 40px;
        position: relative;

        @media (min-width: $desktop) {
            margin-bottom: 100px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .step-point {
            position: absolute;
            display: block;
            width: 11px;
            height: 11px;
            box-sizing: content-box;
            border-radius: 50%;
            right: calc(100% + 15px);
            background: $spike-red;
            border: 10px solid $white;
            box-shadow: 0 0 0 2px $line-gray;

            @media (min-width: $desktop) {
                right: calc(100% + 76px);
            }
        }

        &.step {
            &--3 {
                .illu {
                    width: 100%;
                }
            }
        }
    }

    &__items {
        margin-top: 32px;
    }

    &__item {
        display: flex;
        align-items: center;
        margin: 32px 0;

        img {
            margin-right: 24px;
        }
    }

    &__text {

        @media (min-width: $desktop) {
            padding-right: 50px;
            position: sticky;
            top: 50px;
        }
    }
}
