.touchpoint {
    &-checkbox {
        width: 16px !important;
        height: 16px !important;
        border-width: 2px !important;
        transform: none !important;
        border-radius: 0 !important;

        &:before {
            width: 10px !important;
            height: 10px !important;
            box-shadow: inset 1em 1em !important;
            clip-path: none !important;
        }

        &:checked:before {
            transform: scale(1);
        }
    }

    &-top-btn-group {
        width: fit-content;
    }
}
