.audience-type-row {
    .dropdown-menu {
        font-size: 1.25rem;
    }

    .dropdown-toggle {
        padding: 1rem 1rem !important;
    }

    .dropdown-item {
        padding: 0.65rem 1rem !important;
    }
}
