.view-private-project {
    input[type="checkbox"] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: $teal;
        width: 1.6rem;
        height: 1.6rem;
        border: 1px solid #aaa;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        cursor: pointer;

        &:focus {
            outline: max(2px, 0.15em) solid $teal;
            outline-offset: max(2px, 0.15em);
        }

        &::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $teal;
            background-color: CanvasText;
            transform-origin: bottom left;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }

        &:checked::before {
            transform: scale(1);
        }
    }
}
