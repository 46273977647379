.mapboxgl-map {
    .mapboxgl-control-container {
        .mapboxgl-ctrl-top-right {
            .mapboxgl-ctrl.mapboxgl-ctrl-group {
                box-shadow: none;
                border: 2px solid $border-gray-transparent;
                margin-top: 1rem;
                margin-right: 1rem;

                button {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .mapboxgl-popup {
        &-anchor-right {
            .mapboxgl-popup-tip {
                border-left-color: rgba(0,0,0,.7) !important;
            }
        }

        &-anchor-left {
            .mapboxgl-popup-tip {
                border-right-color: rgba(0,0,0,.7) !important;
            }
        }

        &-anchor-top {
            .mapboxgl-popup-tip {
                border-bottom-color: rgba(0,0,0,.7) !important;
            }
        }

        &-anchor-bottom {
            .mapboxgl-popup-tip {
                border-top-color: rgba(0,0,0,.7) !important;
            }
        }

        &-content {
            background-color: rgba(0,0,0,.7);
            color: white;
            border-radius: 4px;
            font-size: 16px;
            padding: 7px;

            & div:nth-child(1) {
                font-weight: bold;
                color: #7FDBFF;
            }

            & div:nth-child(2) {
                font-weight: normal;
                color: white;
            }

            & div:nth-child(3) {
                font-size: 13px;
                color: $gray-light;
            }
        }

        &-close-button {
            line-height: 1;
            color: $gray-light-bis;

            &:hover {
                color: $spike-red;
                background-color: transparent;
            }
        }
    }
}
