.contact-form {
    & .form-group {
        & label.required {
            &:after {
                content: '✱';
                color: $spike-red;
                font-size: .8rem;
                margin-left: 5px;
                vertical-align: top;
            }
        }
    }
}
