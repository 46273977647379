#view-project-root {
    .item-table-container {
        font-size: 14px;

        thead tr {
            th {
                position: sticky;
                top: -1px;
            }

            &:nth-of-type(1) th {
                top: 0;
            }

            &:nth-of-type(2) th {
                top: 41px;
            }

            &:nth-of-type(3) th {
                top: 82px;
            }
        }

        table {
            color: #545454;

            th {
                padding: 7px 6px;
            }

            tr td {
                text-overflow: ellipsis;
                white-space: normal;
                overflow: hidden;
            }

            td, th {
                vertical-align: middle;
            }
        }
    }

    .item-col-sortable {
        cursor: pointer;

        &:hover i {
            color: $menu;
        }
    }
}
