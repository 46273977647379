.isodistance-tool {
    &-container {
        .distance-tool-container {
            position: absolute;
            bottom: 40px;
            width: 100%;

            .wrapper {
                background: white;
                margin: 0 auto;
                width: 400px;
                border-radius: 100px;
                padding: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

                label {
                    font-size: 13px;
                    color: $black !important;
                    font-weight: 600;
                }

                input[type=range] {
                    margin-top: 5px;
                }

                input[type=number] {
                    padding: 15px 10px;
                }

                .input-traject-time {
                    width: 120px;
                    margin: 0 20px;
                }

                .input-distance {
                    width: 160px;
                }

                .input-group-text {
                    background: $white;
                    font-weight: 600;
                }
            }
        }

        .selection-control-block {
            margin-top: 20px;
        }
    }
}
