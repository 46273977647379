#view-project-root .indicator-view-container {
    .zone-list--container {
        div[class^=" css-"][class$="-container"] {
            z-index: 1000000;
        }

        & thead.sticky-top {
            z-index: 100;
        }
    }

    .active-cross-indicator-row {
        display: flex;
        justify-content: space-between;
        color: $white;
        background-color: #f48f9a;
        padding: 5px;
        border-radius: 3px;

        & > span:first-child {
            svg {
                margin-right: 5px;
            }

            font-weight: bold;
        }
    }

    .cross-table {
        &.item-table-container {
            table {
                thead {
                    &.sticky-top {
                        z-index: 101;
                    }

                    tr th {
                        background-color: #fff;
                        color: $very-dark-blue;
                        white-space: nowrap;
                    }
                }

                tr {
                    th:first-child,
                    td:first-child {
                        max-width: 300px;
                    }

                    &.sticky-top {
                        z-index: 100;
                    }
                }
            }
        }

        &--big {
            tr {
                &.sticky-top {
                    top: 123px;
                }
            }
        }

        &--small {
            tr {
                &.sticky-top {
                    top: 41px;

                    &.sticky-table-small-thead {
                        top: 34px;
                    }
                }
            }
        }
    }

    .zone-change-category-button {
        .dropdown-menu {
            width: 100%;
            border-color: $teal-much-darker;
            font-size: 1.3rem;

            .dropdown-item {
                padding: 0.65rem 1rem;
            }
        }
    }

    .border-separator {
        border-right-color: $blue-new-dark;

        &-1 {
            border-right-width: 1px;
        }

        &-2 {
            border-right-width: 2px;
        }
    }

    .map-tools--container {
        .color-scale-legend {
            &-wrapper {
                padding-right: 5px;

                .active-color-scale-label {
                    width: 17px;
                    height: 17px;
                    position: absolute;
                    top: -7px;
                    right: -7px;
                    display: flex;
                    color: $white;
                    background: $mouse-gray;
                    font-weight: bold;
                    font-size: 1.2rem;
                    justify-content: center;
                    border-radius: 100%;
                    align-items: center;
                    font-family: sans-serif;
                }

                .color-scale-legend--panel {
                    position: absolute;
                    left: 100%;
                    top: -10px;
                    margin-left: 10px;
                    width: 150px;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    background-color: rgba(0, 0, 0, 0.7);
                    color: $white;

                    label {
                        font-weight: bold;
                        color: $teal;
                    }

                    button:hover {
                        color: $teal;
                    }

                    .card {
                        background: transparent;
                        border: 0 none;
                        border-bottom: 2px solid #9d9c9c;

                        &:last-child {
                            border-bottom: 0;
                        }

                        &-header {
                            padding: 5px 0;
                            border: 0;

                            a {
                                font-size: 1.25rem;
                                color: white;
                                font-weight: bold;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                &.active {
                                    color: $teal;
                                }

                                &:hover {
                                    color: $spike-red;
                                    text-decoration: none;
                                }

                            }
                        }

                        &-body {
                            padding: 10px 0 0;

                            label {
                                padding: 0;
                            }
                        }
                    }
                }

                &:hover {
                    background-color: $gray-lighter;
                }
            }
        }
    }
}

#view-project-root,
.redzone-container {
    .header-row {
        align-items: center;

        p {
            margin: 0;
        }

        .project-list {
            display: flex;

            & > div:first-child {
                flex-grow: 1;
            }

            .collapsed-group > div:not(.collapse-group-heading) {
                display: none;
            }

            .collapse-group-heading::before {
                flex-shrink: 0;
                width: 30px;
                height: 30px;
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-19bqh2r'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
                background-repeat: no-repeat no-repeat;
                background-size: 2rem;
                background-position: center center;
                color: rgb(197, 197, 197);
                transform: rotate(180deg);
                background-color: #b9bcc0;
            }

            .collapsed-group .collapse-group-heading::before {
                transform: rotate(0deg);
                background-color: inherit;
            }

            .collapse-group-heading {
                display: flex;
                cursor: pointer;
                align-items: end;

                & > div {
                    cursor: pointer;
                }

                &:hover {
                    background: #dee2e6;
                }
            }
        }
    }

    .project-description {
        display: none;
        padding-left: .5rem;
        font-size: 14px;
        margin-bottom: 8px;

        p {
            margin: 0;

            span {
                &:not(:last-child):after {
                    content: '/';
                    font-weight: normal;
                    margin: 0 5px
                }
            }

            &:last-child {
                margin-top: 2px;
                font-size: 13px;
            }
        }
    }

    .project-map-container {
        .map-wrapper-inner {
            height: 650px;
        }
    }

    .cross-indicators--table {
        table {
            th {
                text-align: center;
            }
        }
    }

    .item-table-container {
        &.item-table-container--scroll {
            position: relative;

            .item-table-container-in {
                max-height: 420px;
                min-height: 400px;
                overflow-y: auto;
            }
        }

        table.table {
            height: 100%;
            overflow-y: auto;

            tr th {
                font-size: 1.25rem;
            }

            .active-item {
                color: $white;
                background-color: #f48f9a;
            }
        }
    }

    .table-container {
        &__table {
            .item-table-container {
                &.item-table-container--scroll {
                    .item-table-container-in {
                        max-height: 600px;
                    }
                }
            }
        }

        &__map-table {
            position: relative;

            .table-demo-panel {
                position: absolute;
                bottom: 0;
                padding: 40px;
                text-align: center;
                font-weight: bold;
                background: rgb(255,255,255);
                background: linear-gradient(0deg, rgba(255,255,255,1) 80%, rgba(0,212,255,0) 100%);
                width: 100%;
            }

            &.is-demo-user {
                table tbody tr:nth-child(n+11) {
                    filter: blur(2px);
                }
            }
        }
    }

    .color-scale-legend-wrapper {
        position: relative;
        font-size: 11px;
        border-radius: 3px;
        z-index: 1;
        background-color: rgba(255, 255, 255, .75);

        & > div {
            cursor: pointer;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: flex;
                flex-direction: row;
                align-items: center;

                .block {
                    &-color,
                    &-value {
                        height: 20px;
                        line-height: 20px;
                    }

                    &-color {
                        width: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .indicator-lv {
        &-1 {
            background-color: #0F4069;
        }

        &-2 {
            background-color: #146382;
        }

        &-3 {
            background-color: #1C86A0;
        }

        &-4 {
            background-color: #25A8BA;
        }

        &-5 {
            background-color: #5A96AE;
        }

        &-6 {
            background-color: #937688;
        }

        &-7 {
            background-color: #E94657;
        }
    }

    .project-filters-container {
        background-color: $gray-lighter;
        border: 1px solid $gray-light-border;
        font-size: 1.25rem;

        .modal-content {
            font-size: 1.6rem;
        }

        label {
            color: $gray-very-dark;
        }

        input {
            font-size: 1.25rem;
        }

        input[disabled].form-control {
            border-radius: 4px;
            min-height: 38px;
            background-color: hsl(0, 0%, 95%);
            color: hsl(0, 0%, 50%);
            border-color: hsl(0, 0%, 90%);
            cursor: not-allowed;
        }

        .row .col-3 {
            padding-right: 10px;

            &:last-child {
                padding-right: 0;
            }
        }

        .manage-project-zone-link {
            font-size: 23px;

            a {
                color: $gray-very-dark;

                &:hover {
                    color: $vivid-red;
                }
            }
        }

        .project-zones-manager {
            table {
                @include table-header;

                a {
                    color: $gray-very-dark;

                    &:hover {
                        color: $vivid-red;
                    }
                }

                .new-name {
                    height: 100%;
                    width: 77%;
                }
            }
        }

        .interest-areas-manage {
            position: relative;

            &-button {
                position: absolute;
                right: 0;
                bottom: calc(100% + 8px);
                color: $teal-much-darker;
                font-weight: 600;
                font-size: 13px;
                display: inline-block;
                text-decoration: none;
                font-family: 'Inter', sans-serif;
                cursor: pointer;
                text-align: center;
                transition: all 150ms ease-in-out;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-color: transparent;
                border: none;
                padding: 0;
                margin: 0;

                img {
                    position: relative;
                    top: -2px;
                    margin-right: 4px;
                }
            }
        }

        .interest-areas-type {
            display: flex;

            .interest-area {
                display: inline-block;
                text-decoration: none;
                font-family: 'Inter', sans-serif;
                cursor: pointer;
                text-align: center;
                transition: all 150ms ease-in-out;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-color: #fff;
                border: 1px solid $line-gray;
                border-radius: 4px;
                flex: 1;
                padding: 10px 0;
                margin: 0 5px;

                &:disabled {
                    cursor: not-allowed;
                }

                &:not(:disabled):hover {
                    border-color: $mouse-gray-lighter;
                }

                &:first-child { margin-left: 0;}
                &:last-child { margin-right: 0;}

                img {
                    display: block;
                    margin: 0 auto 4px;
                }
            }
        }
    }
}

.view-private-project {
    .map-container {
        height: auto !important;
    }

    .item-table-container {
        table {
            @include table-header;
        }
    }

    .map-tools--container {
        &-left {
            max-width: 120px;
        }

        .slider {
            input[type=range] {
                -webkit-appearance: none;
                color: $black;
                opacity: .8;
                background: #D0D3D1;
                border-radius: 50px;
                height: 7px;
                margin-top: 10px;
            }

            input[type=range]::-webkit-slider-thumb {
                margin-top: auto;
                width: 12px;
                height: 12px;
                border-radius: 50px;
                background: $black;
                border: 1px solid #000000;
                -webkit-appearance: none;
            }

            label {
                display: block;
                font-size: smaller;
            }
        }

        .indicator-switch,
        .indicator-level-switch,
        .potential-mode-switch,
        .interest-point-indicator {
            label {
                vertical-align: middle;
                margin-left: 7px;
                cursor: pointer;
            }

            input[type="radio"] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                width: 14px;
                height: 14px;
                border-radius: 50%;
                position: relative;
                border: 1px solid black;

                &:after {
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    left: 2px;
                    top: 2px;
                    background-color: white;
                }

                &:checked:after {
                    background-color: $black;
                }
            }
        }

        .interest-point-indicator {
            ul {
                list-style: none;
                padding: 0;

                li {
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-right: 5px;
                        border: 1px solid rgba(0, 0, 0, 0.5);
                    }

                    &:nth-child(1):before {
                        background-color: #FFFAB3;
                    }
                    &:nth-child(2):before {
                        background-color: #E6E6E6;
                    }
                    &:nth-child(3):before {
                        background-color: #97FFD5;
                    }
                    &:nth-child(4):before {
                        background-color: #FFA3A3;
                    }
                    &:nth-child(5):before {
                        background-color: #A3C4FF;
                    }
                }
            }
        }

        .tools-control {
            .arrow-trigger {
                height: 8px;
                cursor: pointer;
                text-align: center;
                font-size: 10px;
            }

            .tools-content {
                display: none;
                margin-top: 5px;
            }

            &:hover {
                & .arrow-trigger {
                    display: none;
                }

                & .tools-content {
                    display: block;
                }
            }
        }
    }

    .control-switcher {
        .custom-switch {
            background-color: rgba(255, 255, 255, .75);
            padding: 5px 5px 5px 36px;
            border-radius: 3px;

            &:hover {
                .custom-control-label span {
                    color: $vivid-red;
                }
            }

            .custom-control-label {
                cursor: pointer;

                span {
                    width: 86px;
                    display: inline-block;
                    text-align: center;
                }

                &:before {
                    top: 2px;
                    left: -30px;
                    color: white;
                    border-color: $gray-very-dark;
                    background-color: white;
                    width: 25px;
                    height: 14px;
                    border-radius: 7px;
                }

                &:after {
                    width: 10px;
                    height: 10px;
                    top: 4px;
                    left: -17px;
                    background-color: $gray-very-dark;
                }
            }
        }

        .spin-loader {
            margin: 0;
            display: none;
            width: 15px;
            height: 15px;
            border-width: 3px;
        }

        &.loading {
            position: relative;

            .spin-loader {
                position: absolute;
                display: inline-block;
                right: 3px;
                top: 7px;
                z-index: 1000;
            }
        }
    }

    .custom-control-input:checked {
        & ~ .custom-control-label {
            &:before {
                background-color: white;
            }

            &:after {
                transform: translateX(-11px);
                background-color: $gray-very-dark;
            }
        }
    }
}

.app__customer_area__project__view,
.app__customer_area__project__table,
.app_public_project_show {
    nav.navbar {
        padding: 0 1rem;
    }

    .btn-group .btn {
        font-size: 1.25rem !important;
    }
}


.hovered-link {
    a {
        display: none;
    }

    &:hover {
        position: relative;

        a {
            position: absolute;
            display: inline;
        }
    }
}
