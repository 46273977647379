// new nav
.navbar {
    flex-grow: 1;
    width: 100%;
    padding: 20px 16px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: $desktop) {
        padding: 20px 24px;
    }

    .navbar-brand {
        margin-right: 32px;
    }

    .navbar-nav {
        width: 100%;

        .nav-item {
            position: relative;
            margin-right: 20px;
            cursor: pointer;

            &.separator {
                border-left: 1px solid $spike-red;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active {
                .nav-link {
                    color: $mouse-gray;
                }
            }

            .nav-link {
                color: $blue-darker;
                border: none;
                font-size: 18px;
                font-weight: $semi-bold;
                line-height: 24px;

                &:hover {
                    background-color: transparent;
                    border: none;
                    color: $mouse-gray;
                }
            }

            .nav-dropdown {
                position: absolute;
                top: 100%;
                left: -10px;
                padding-top: 10px;
                z-index: 500;
                display: none;

                &-content {
                    background: $white;
                    box-shadow: 0 0 14px rgba(0, 0, 0, 0.05);
                    border-radius: 4px;
                    width: 400px;
                    padding: 15px;
                }

                &-item {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 10px;
                    border-radius: 4px;
                    background-color: $white;
                    transition: all 125ms;

                    &:hover {
                        background-color: $gray-light-bis;
                    }

                    &__title {
                        font-size: $text-size-default;
                        font-weight: 600;
                        color: $blue-darker;
                        margin-bottom: 0;
                    }

                    &__description {
                        color: $mouse-gray-lighter;
                        font-size: $text-size-default;
                        margin-bottom: 0;
                    }

                    &__picto {
                        margin-right: 20px;
                        height: 70px;
                    }
                }
            }

            &.nav-expandable {

                .nav-link {
                    padding-right: 18px;

                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 14px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 5px 4px 0 4px;
                        border-color: $mouse-gray-lighter transparent transparent transparent;
                    }

                    &:hover::after {
                        border-color: $mouse-gray transparent transparent transparent;
                    }
                }

                &:hover {
                    .nav-dropdown {
                        display: block;
                    }
                }
            }

        }
    }

    &-light {
        background-color: $white;

        .sw-logo-white {
            display: none;
        }

        .sw-logo-color {
            display: block;
        }
    }

    &-dark {
        background-color: $blue-dark;

        .sw-logo-white {
            display: block;
        }

        .sw-logo-color {
            display: none;
        }

        .navbar-nav {
            .nav-item {
                &.active {
                    .nav-link {
                        color: $white;
                    }
                }

                .nav-link {
                    color: #C3CFD9;

                    &:hover {
                        color: $white;
                    }
                }

                &.nav-expandable {

                    .nav-link {

                        &::after {
                            border-color: #c3cfd9 transparent transparent transparent;
                        }

                        &:hover::after {
                            border-color: $white transparent transparent transparent;
                        }
                    }
                }

                .icn-customer-space path {
                    fill: $white;
                }
            }
        }
    }
}
