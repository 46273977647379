.sw-table {
    font-size: 14px;
    overflow-x: auto;

    &__wrapper {
        min-width: 690px;
    }

    &__head {
        display: flex;
        position: relative;
        border-bottom: 1px solid $gray-light-bis;
        background-color: #fff;
        font-weight: bold;
        color: $mouse-gray-lighter;
        font-size: 16px;
        white-space: nowrap;
    }

    &__row {
        display: flex;
        position: relative;
        border-bottom: 1px solid $gray-light-bis;

        &.selected {
            background-color: $mouse-gray-lighter;
        }
    }

    &__row.selected {
        background-color: #F8F9FA;
    }

    &__row.selected::before {
        content: '';
        position: absolute;
        width: 3px;
        top: 0;
        bottom: 0;
        background-color: $spike-red;
    }

    &-col {
        flex: 1;
        padding: 10px 16px;
        min-height: 58px;
        display: flex;
        align-items: center;

        &--center {
            justify-content: center;
        }

        &--sortable {
            cursor: pointer;

            i {
                margin-left: 5px;
            }

            &:hover {
                i {
                    color: $spike-red;
                }
            }
        }
    }

    &-col-vertical {
        flex-direction: column;
        align-items: flex-start;
    }

    &-compl-info {
        font-size: 12px;
        color: $mouse-gray-lighter;
        margin-top: 4px;
    }

    &-tag {
        padding: 8px 12px;
        border: 1px solid $line-gray;
        border-radius: 50px;
        margin-right: 8px;
        line-height: 17px;
        background-color: $white;
        white-space: nowrap;
    }
}
