.customer-area {
    .pre-table_row {
        border-top: 1px solid $filter-field-border;

        .project-filters {
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                background-image: linear-gradient(45deg, transparent 50%, gray 50%),
                linear-gradient(135deg, gray 50%, transparent 50%),
                linear-gradient(to right, #ccc, #ccc);
                background-position: calc(100% - 20px) calc(1em + 2px),
                calc(100% - 15px) calc(1em + 2px),
                calc(100% - 2.5em) 0.5em;
                background-size: 5px 5px,
                5px 5px,
                1px 1.5em;
                background-repeat: no-repeat;
            }

            .form__field {
                box-sizing: border-box;
                display: block;
                @include filter-field-text;
                width: 100%;
                color: $dark;
                margin: 0;
                line-height: 1.5em;
                padding: 0.5em 3.5em 0.5em 1em;
                border-radius: 2px;
                border: solid 1px $filter-field-border;
                background-color: $white;
            }
        }
    }

    .text-help {
        border-left: 4px solid #718096;
        border-radius: 5px;
        color: #718096;
        font-size: 15px;
        background-color: rgba(172, 195, 230, 0.3);
        padding: 10px 15px;
    }

    .link {
        color: $menu;


        &:hover {
            text-decoration: none;
            color: $spike-red;
        }
    }

    .project-group-items {
        a.btn:hover {
            border-bottom: 2px solid $blue-dark;
        }
    }
}
