label.sw-switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
    margin-bottom: 0;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: $mouse-gray;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px $mouse-gray;
        }

        &:checked + .slider:before {
            transform: translateX(1.5rem);
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .3s;
        border-radius: 2rem;

        &:before {
            position: absolute;
            content: "";
            height: calc(1.5rem - 4px);
            width: calc(1.5rem - 4px);
            left: 2px;
            bottom: 2px;
            border-radius: 50%;
            background-color: white;
            transition: .3s;
        }
    }
}
