@import 'vars';

$text-size-xx-large: 55px;
$text-size-x-large: 45px;
$text-size-large: 35px;
$text-size-medium: 20px;
$text-size-default: 16px;
$text--size-small: 14px;
$text--size-x-small: 12px;

$medium: 500;
$semi-bold: 600;
$bold: 700;

@mixin filter-field-text {
    font-family: $font-stack;
    font-size: $text--size-small;
    font-weight: normal;
    line-height: normal;
}

.f-s {
    &__smallest-2 {
        font-size: 8px;
    }

    &__smallest {
        font-size: 10px;
    }

    &__12 {
        font-size: 12px;
    }

    &__13 {
        font-size: 13px;
    }

    &__medium {
        font-size: $text--size-small !important;
    }
}

.text {
    &__gray {
        color: $gray;
    }

    &__very_dark_blue {
        color: $very-dark-blue;
    }

    &__error {
        color: $vivid-red;
    }

    &__teal-much-darker {
        color: $teal-much-darker;
    }

    &-highlight {
        background-color: #FCF4D4;
    }

    &__right {
        text-align: right;
    }

    &__left {
        text-align: left;
    }

    &__center {
        text-align: center;
    }

    // sizes
    &-xx-large {
        font-size: 45px;
        @media (min-width: $mobile) {
            font-size: $text-size-xx-large;
        }
    }

    &-x-large {
        font-size: 35px;
        @media (min-width: $mobile) {
            font-size: $text-size-x-large;
        }
    }

    &-large {
        font-size: 28px;
        @media (min-width: $mobile) {
            font-size: $text-size-large;
        }
    }

    &-s-medium {
        font-size: $text-size-medium;
    }

    &-small {
        font-size: $text--size-small;
    }

    &-x-small {
        font-size: $text--size-x-small;
    }

    // colors
    &-dark-blue {
        color: $blue-darker;
    }

    &-gray {
        color: $mouse-gray;
    }

    &-gray-light {
        color: $mouse-gray-lighter;
    }

    &-white {
        color: $white;
    }

    &-black {
        color: $black !important;
    }

    &-red {
        color: $spike-red;
    }

    &-teal {
        color: $teal;
    }

    &-apple {
        color: $apple-green;
    }

    // weights
    &-medium {
        font-weight: $medium;
    }

    &-semi-bold {
        font-weight: $semi-bold;
    }

    &-bold {
        font-weight: $bold;
    }
}

.subtitle {
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    font-size: 18px;
    font-weight: $bold;
    padding-left: 25px;
    letter-spacing: 3px;

    &::before {
        content: '';
        display: block;
        width: 15px;
        height: 2px;
        position: absolute;
        left: 0;
        top: 12px;
    }

    &-teal {
        color: $teal;

        &::before {
            background-color: $teal;
        }
    }

    &-red {
        color: $spike-red;

        &::before {
            background-color: $spike-red;
        }
    }

    &-apple {
        color: $apple-green;

        &::before {
            background-color: $apple-green;
        }
    }
}
