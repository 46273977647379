.sct-key-survey {
    &__container {
        background-color: $white;
        padding: 40px;
        width: 100%;
        max-width: 364px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
    }

    &__title {
        font-size: 24px;
        line-height: 28px;
        position: relative;
        z-index: 5;
    }

    &__label {
        font-size: 18px;
        position: relative;
        z-index: 5;
    }

    &__value {
        font-size: 18px;
        position: relative;
        z-index: 5;
    }

    &__logo-showhere {
        width: 90px;
        margin-top: 40px;
        position: relative;
        z-index: 5;
    }

    &__decorative {
        position: absolute;
        pointer-events: none;
        z-index: 1;
        right: 0px;
        top: 110px;
    }
}
