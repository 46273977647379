.app__customer_area__project__table {
    .table {
        thead {
            th {
                color: $black;
                font-size: 1.25rem;
            }

            .column-names-row th {
                color: $gray-dark;
                font-size: 1rem;
            }

            .sort-columns-row th {
                font-size: 1.1rem;
            }
        }

        .border-separator {
            border-right-color: $blue-new-dark;

            &-2 {
                border-right-width: 2px;
            }
        }
    }
}
