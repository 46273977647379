.share-widget {
    position: relative;
    cursor: pointer;
    padding: 10px;

    .input-container {
        position: absolute;
        display: none;
        background: rgba(0, 0, 0, .5);
        padding: 10px;
        border-radius: 3px;
        z-index: 99999;
        width: 300px;
        left: -130px;

        label {
            display: block;
            font-size: 14px;
            background: white;
            text-align: left;
            border-radius: 3px;
            padding: 3.75px 7.5px;
        }

        input {
            border: 0 none;
        }
    }

    &:hover {
        i {
            color: $menu;
        }

        .input-container {
            display: block;
        }
    }
}

@media screen and (max-width: 768px) {
    .share-widget {
        .input-container {
            left: -240px;
        }
    }
}
