.zone-list {
    &--container {
        position: relative;
        box-sizing: border-box;
    }

    &--control {
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0!important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;
    }

    &--value-container {
        align-items: center;
        display: flex;
        flex: 1;
        padding: 2px 8px;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        height: 32px;
    }

    &--value {
        background-color: hsl(0, 0%, 90%);
        border-radius: 2px;
        display: flex;
        margin: 2px;
        min-width: 0;
        box-sizing: border-box;
        align-items: center;

        & .zone-list--value-name {
            border-radius: 2px;
            color: hsl(0, 0%, 20%);
            font-size: 85%;
            overflow: hidden;
            padding: 3px;
            padding-left: 6px;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;

            &:hover {
                background-color: hsl(0, 0%, 80%);
            }
        }

        &__close-button {
            align-items: center;
            border-radius: 2px;
            display: flex;
            padding-left: 4px;
            padding-right: 4px;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background-color: $spike-red;
            }
        }
    }

    &--placeholder {
        color: hsl(0, 0%, 50%);
        grid-area: 1/1/2/3;
        margin-left: 2px;
        margin-right: 2px;
        box-sizing: border-box;
    }

    &--indicators {
        &-container {
            align-items: center;
            align-self: stretch;
            display: flex;
            flex-shrink: 0;
            box-sizing: border-box;
        }

        &-separator {
            align-self: stretch;
            background-color: hsl(0, 0%, 80%);
            margin-bottom: 8px;
            margin-top: 8px;
            width: 1px;
            box-sizing: border-box;
        }
    }

    &--indicator-container {
        color: hsl(0, 0%, 80%);
        display: flex;
        padding: 8px;
        transition: color 150ms;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
            background-color: $spike-red;
        }
    }

    &--menu {
        background: white;
        border-radius: 4px;
        position: absolute;
        width: 900px;
        right: 0;
        z-index: 10000;
        box-sizing: border-box;
    }

    &--table-wrapper {
        max-height: 500px;
    }
}
