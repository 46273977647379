.close-button {
  position: absolute;
  font-size: 2rem;
  cursor: pointer;
  right: -5px;
  top: -5px;
  display: flex;
  width: 15px;
  height: 15px;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  border: 1px solid white;

  &:hover {
    color: $spike-red;
    border-color: $spike-red;
  }
}
