.btn {
    border-radius: 50px;
    font-size: 1.4rem !important;

    &-sm {
        padding: 8px 12px;
        font-weight: $bold;
        font-size: 13px !important;
    }

    &-smallest {
        font-size: 10px !important;
    }

    &-md {
        padding: 12px 20px;
        font-weight: $bold;
        font-size: 15px !important;
    }

    &-lg {
        padding: 15px 30px;
        font-weight: $bold;
        font-size: 17px !important;
    }

    &-teal {
        color: $white;
        background-color: $teal;
        border-color: transparent;

        &:hover {
            color: $white;
            background-color: $teal-darker;
        }

        &-dark {
            color: $white;
            background-color: $teal-darker;

            &:hover {
                color: $white;
                background-color: lighten($teal-darker, 5%);
            }
        }
    }

    &-link--teal-dark {
        color: $teal-much-darker;

        &:hover {
            color: $teal;
        }
    }

    &-link--gray {
        color: $mouse-gray;

        &:hover {
            color: $teal;
        }
    }

    &-teal-reversed {
        color: $teal;
        background-color: transparent;
        border-color: $teal;

        &:hover {
            background-color: $teal-darker;
            color: $white;
            border-color: $teal-darker;
        }
    }

    &-red {
        color: $white;
        background-color: $spike-red;
        border-color: transparent;

        &:hover {
            background-color: $spike-red-darker;
            color: $white;
        }
    }

    &-hover {
        &-red:hover {
            color: $white;
            background-color: $spike-red !important;
            border-color: transparent;
        }
    }

    &-terciary {
        color: $mouse-gray;
        background-color: $gray-light-bis;
        border-color: transparent;

        &:hover {
            background-color: #DCDEE1;
        }
    }

    &-icon {
        width: 37px;
        height: 37px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
            display: inline-block;
            max-width: none;
            width: 16px;
        }

        &.btn-sm {

        }
    }

    &-group {
        &-no-ml {
            button:not(:first-child) {
                margin-left: 0 !important;
            }
        }
    }

}

.sw-btn {
    &--orange {
        color: white;
        background-color: #F9A736;
    }

    &--red {
        color: white;
        background-color: #EE4053;
    }

    &--menu-red {
        color: white;
        background-color: $spike-red;

        &:hover {
            color: white;
            background-color: darken($spike-red, 15%);
        }

        &.active {
            background-color: $blue-dark;
            border-bottom: 2px solid $spike-red;
        }
    }

    &--red-bordered {
        color: $spike-red;
        border: 1px solid $spike-red !important;

        &:hover {
            color: white;
            background-color: darken($spike-red, 15%);
        }
    }

    &--big {
        padding: 10px 20px;
        text-transform: uppercase;
        border-radius: 10px;
    }
}
