.sct-press {
    &__container {
        display: flex;
        align-items: center;
        padding: 30px;
        border: 1px solid $line-gray;
        border-radius: 4px;
        text-decoration: none !important;
        height: 100%;

        img {
            max-width: 90px;
        }
    }

    &__content {
        margin-left: 30px;
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        color: $blue-dark;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__date {
        font-size: $text-size-medium;
        color: $mouse-gray-lighter;
    }
}
