html {
    font-size: 62.5%;
    height: 100%;
}

body {
    font-family: 'Calibri', 'Inter', 'sans-serif';
    font-size: 1.6rem;
    color: $mouse-gray;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.h100 {
        height: 100%;

        &.over-hidden {
            overflow-y: hidden;
        }

        & > main {
            min-height: 100%;
        }
    }
}

@media (min-width: 1400px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1320px;
    }
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'ArialBlack', 'Inter', 'sans-serif' !important;
    font-weight: 400 !important;
}

img {
    max-width: 100%;
}

ul ul {
    list-style: disc;
}

.text-link {
    color: $teal;

    &:hover {
        color: $teal-darker;
        text-decoration: none;
    }
}

.b-shadow {
    box-shadow: 0 21px 21px 0 rgba(103, 134, 123, 0.2);
}

.sw-logo-sm {
    width: 129px;
}

.dropdown {
    .dropdown-menu {
        font-size: 1.6rem;
    }
}

.numbered-list {
    list-style: none;
    padding: 0;

    &__item {
        padding-left: 48px;
        position: relative;
        margin: 20px 0;

        span.number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: $bold;
            left: 0;
            top: -3px;
            width: 32px;
            height: 32px;
            border-radius: 50px;
        }
    }

    &.list-teal {
        .number {
            background-color: $teal-lighter;
            color: $teal;
        }
    }

    &.list-red {
        .number {
            background-color: $spike-red-lighter;
            color: $spike-red;
        }
    }
}

.illu__pannel {
    padding: 24px;
    border-radius: 6px;
    width: 170px;

    .title {
        font-size: 20px;
        font-weight: $semi-bold;
    }

    .detail {
        font-size: 16px;
    }

    &--white {
        background-color: $white;

        .title {
            color: $blue-dark;
        }

        .detail {
            color: $mouse-gray-lighter;
        }
    }

    &--red {
        background-color: $spike-red;

        .title {
            color: $white;
        }

        .detail {
            color: $white;
            opacity: 0.75;
        }
    }
}

.w {
    &-40 {
        width: 40%;
    }
}

.form-control,
.btn {
    font-size: 1.6rem;
}

.form-control {
    &:not(&-sm):not(textarea) {
        height: 38px;
    }
}

.page-hero {
    background-color: $blue-dark;
    padding: 60px 0;
    background: url('/images/background-theme-partial-01.jpg'), $blue-dark;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 80% auto;

    @media (min-width: $mobile) {
        background-size: 50% auto;
        padding: 90px 0;
    }

    p {
        font-size: 24px;
        opacity: 0.75;
        width: 80%;
    }

    .w-80 {
        width: 80%;
    }
}

.sct {
    padding: 50px 0;

    @media (min-width: $tablet) {
        padding: 70px 0;
    }

    &-first {
        padding-top: 50px;

        @media (min-width: $tablet) {
            padding-top: 120px;
        }
    }

    &-last {
        padding-bottom: 50px;

        @media (min-width: $tablet) {
            padding-bottom: 120px;
        }
    }
}

.display {
    &-5 {
        font-size: 2.5rem;
    }

    &-6 {
        font-size: 2.2rem;
    }

    &-7 {
        font-size: 2rem;
    }

    &-8 {
        font-size: 1.8rem;
    }

    &-9 {
        font-size: 1.6rem;
    }

    &-10 {
        font-size: 1.4rem;
    }

    &-11 {
        font-size: 1.2rem;
    }
}

.bg {
    &-blue {
        background-color: $blue;
    }

    &-red {
        background-color: $spike-red !important;
    }

    &-blue-dark {
        background-color: $blue-dark;
    }

    &-blue-light {
        background-color: $teal-lighter;
    }

    &-teal {
        background-color: $teal-much-darker;
    }

    &-gray-light {
        background-color: $gray-light-bis;
    }

    &-gradient-gray-light {
        background: linear-gradient($white, $gray-light-bis);
    }
}

.color {
    &-white {
        color: white !important;
    }

    &-blue {
        color: $blue;
    }

    &-blue-dark {
        color: $blue-dark;
    }

    &-red {
        color: $spike-red;
    }

    &-black {
        color: black;
    }
}

.footer {
    background-color: $menu;
    font-size: 1.4rem;

    .background {
        height: 170px;
        background-image: url('/images/logo-big3.png');
        background-position: right 1.6rem;
        background-repeat: no-repeat;
        background-size: contain;

        a {
            color: white;

            &:hover {
                text-decoration: none;
                color: $blue-dark;
            }
        }
    }
}

img.loop {
    transform: rotateZ(50deg);
}

.hidden {
    display: none !important;
}

.company-name {
    color: $menu;
}

body {
    & > .alert {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1200;
        border-radius: 0;
        padding: 15px 0;

        &-dismissible {
            .close {
                font-size: 2.5rem;
                padding: 14px;
            }
        }
    }

    .breadcrumbs {
        list-style: none;
        margin: 0;
        padding: 0;
        color: #718096;

        li {
            display: inline;
            font-size: 14px;

            &:not(:first-child) {
                &:before {
                    content: '❯';
                    color: #808080;
                    background: rgba(37, 52, 66, 0.05);
                    line-height: 1;
                    border-radius: 5px;
                    padding: 1px 8px;
                    margin: 0 10px 0 5px;
                }
            }
        }
    }
}

.sw-alert {
    padding: 30px 30px 30px 74px;
    border-radius: 4px;

    p {
        margin: 0;
    }

    &__info {
        background-color: $teal-lighter;
        background-image: url("/images/svg/icn-info.svg");
        background-repeat: no-repeat;
        background-position: 30px 30px;
    }
}

.pointer {
    cursor: pointer;
}

.flex {
    &__align {
        &-self--center {
            align-self: center;
        }

        &-self--end {
            align-self: end;
        }

        &-items-center {
            align-items: center;
        }
    }

    &__wrap {
        flex-wrap: wrap;
    }
}

.visible--ellipsis {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;

    &:hover {
        text-overflow: inherit !important;
        white-space: normal !important;
        overflow: visible !important;
    }
}

.max-width {
    &--200 {
        max-width: 200px;
    }

    &--300 {
        max-width: 300px;
    }
}

.row {
    .justify-content {
        &-space-evenly {
            justify-content: space-evenly;
        }
    }
}

.sw-gap {
    &-2 {
        gap: 2rem;
    }
}
