.breadcrumb {
    background-color: $white;
    padding: 0;
    margin: 0;
    align-items: center;

    .separator {
        margin: 0 2px;

        img {
            width: 18px;
            transform: rotate(-90deg);
        }
    }
}
