// Loader 1
@keyframes bigSpin {
    0% {
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    margin: 10px auto;
    width: 50px;
    height: 50px;

    &__edge {
        position: absolute;
        margin: 0 auto;
        width: 15px;
        height: 15px;
        border: 6px solid rgba(239, 239, 239, 1);
        border-radius: 50%;
        border-left-color: rgba(213, 213, 213, 1);
        border-top-color: rgba(213, 213, 213, 1);

        &--small {
            animation: bigSpin .8s linear infinite;
            animation-origin: 50% 50%;
            width: 7.5px;
            height: 7.5px;
            margin-top: 8px;
            margin-left: 8px;
        }

        &--big {
            text-align: center;
            animation: bigSpin 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
            transform-origin: 50% 50%;
        }
    }
}

// Loader 2
.spin-loader,
.spin-loader:after {
    border-radius: 50%;
}

.spin-loader {
    &-wrapper {
        display: inline-block;
    }

    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;

    font-size: 4px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(29, 95, 209, 0.2);
    border-right: 1.1em solid rgba(29, 95, 209, 0.2);
    border-bottom: 1.1em solid rgba(29, 95, 209, 0.2);
    border-left: 1.1em solid $menu;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spinload 1.1s infinite linear;
    animation: spinload 1.1s infinite linear;

    &--small {
        width: 15px;
        height: 15px;
    }

    &--medium {
        width: 20px;
        height: 20px;
    }

    &--big {
        width: 35px;
        height: 35px;
    }
}

@-webkit-keyframes spinload {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinload {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
