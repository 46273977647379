body {
    &.app_user_customer_login,
    &.app_admin_login {
        & > main {
            .container-fluid {
                display: flex;
                flex-direction: column;
                align-items: center;

                .customer-login-container {
                    flex: 1 1 auto;
                    display: flex;
                    padding: 1rem 0;

                    .card-body {
                        padding: 40px
                    }
                }
            }
        }
    }
}
