.highlighted {
    &--teal-darker {
        background-color: $teal-much-darker !important;
        color: #fff !important;
    }

    &--teal-lighter {
        background-color: $teal-lighter-2 !important;
        color: $very-dark-blue !important;
    }

    &--gray-light {
        background-color: $gray-light-border !important;
        color: $very-dark-blue !important;
    }
}
