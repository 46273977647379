.banner {
    background: rgb(0, 26, 101);
    background: radial-gradient(circle, rgba(0, 26, 101, 1) 0%, rgba(11, 95, 195, 1) 100%);

    color: #fff;

    a {
        color: #00e8ff;
    }
}
