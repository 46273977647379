#view-project-root,
.redzone-container {
    & .map-container {
        position: relative;

        .map-wrapper {
            position: relative;

            &-inner {
                height: 500px;

                .marker {
                    background-image: url("/images/marker.png");
                    background-size: contain;
                    width: 27px;
                    height: 35px;
                    position: relative;

                    &.marker-yellow {
                        background-image: url("/images/marker-yellow.png");
                    }

                    &:hover {
                        &:after {
                            content: '\2715';
                            cursor: pointer;
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: -5px;
                            right: -5px;
                            width: 15px;
                            height: 15px;
                            background: $spike-red;
                            border-radius: 50%;
                            border: 1px solid $spike-red;
                        }
                    }
                }
            }

            .sw-watermark {
                position: absolute;
                z-index: 1000;
                bottom: 3px;
                left: 10px;
                color: $blue-new-dark;
                font-weight: bold;
                font-size: 14px;
            }
        }

        .map-tools--container {
            font-size: 1.25rem;
            position: absolute;
            z-index: 1000;
            border-radius: 3px;

            &-left {
                left: 1rem;
                top: 1rem;
            }

            &-right {
                right: 1rem;
                top: 8.4rem;
            }

            .map-tool {
                cursor: pointer;
                border: 2px solid $border-gray-transparent;
                text-align: center;
                border-radius: 4px;
                background: $white;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: content-box;

                &:hover {
                    border-color: $spike-red;
                }

                &:active {
                    background: $teal;
                    border-color: $teal;
                }

                &--group {
                    .map-tool {
                        &:first-child {
                            border-bottom-width: 1px;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            border-bottom-color: $spike-red;
                        }

                        &:last-child {
                            border-top-width: 1px;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            border-top-color: $spike-red;
                        }
                    }
                }

                &.active {
                    border-color: $spike-red;
                }
            }
        }
    }
}
